
import { DisputeDetail } from "@/store/dispute/dispute-models";
import { Key } from 'ts-key-enum';
import DisputeIssuerEvidenceFile from "./DisputeIssuerEvidenceFile.vue";
import ActionButton from "@/components/controls/ActionButton.vue";
import { disputeStore } from "@/store/store";
import Vue, { PropType } from 'vue';

enum ContentType {
  Unknown,
  Image,
  Pdf,
}

export default Vue.extend({
  name: 'DisputeIssuerEvidence',
  components: {
    "action-button": ActionButton,
    "dispute-evidence-file-view": DisputeIssuerEvidenceFile,
  },
  props: {
    dispute: {
      type: Object as PropType<DisputeDetail>,
      required: true,
    },
  },
  data() {
    return {
      displayEvidenceOverlay: false,
      isLoadingContent: true,
      fileContentUrl: undefined as string | undefined,
      fileContentType: ContentType.Unknown,
    };
  },
  computed: {
    isPdf(): any {
      return this.fileContentType === ContentType.Pdf;
    },
  },
  watch: {
    'displayEvidenceOverlay': function() {
      this.onVisibilityChanged();
    },
  },
  beforeDestroy() {
    if (this.fileContentUrl) {
      // Apparently necessary to avoid memeory leaks...
      URL.revokeObjectURL(this.fileContentUrl);
    }
  },
  async mounted() {
    if (this.dispute && this.dispute.issuerEvidence) {
      let hasMessage = false;
      let fileCount = 0;
      let file: any = null;
      this.dispute.issuerEvidence.forEach(e => {
        if (e.textEvidence) {
          hasMessage = true;
        }
        if (e.fileEvidence) {
          e.fileEvidence.forEach(f => {
            if (!file) {
              file = f;
            }
            fileCount++;
          });
        }
      });

      if (!hasMessage && fileCount === 1) {
        const contentType = this.getFileContentType(file.type);

        if (contentType === ContentType.Pdf) {
          const fileContentBlob = await disputeStore.getEvidenceFileBlob({
            disputeId: this.dispute.id,
            categoryOrFileId: file.id,
          });
          this.fileContentUrl = window.URL.createObjectURL(fileContentBlob);
          this.fileContentType = contentType;
        }
      }
    }

    this.isLoadingContent = false;
  },
  methods: {
    getFileContentType(type: string) {
      switch (type.toLowerCase()) {
        case 'pdf':
          return ContentType.Pdf;
        case 'png':
        case 'jpg':
        case 'jpeg':
          return ContentType.Image;
        default:
          return ContentType.Unknown;
      }
    },
    onVisibilityChanged() {
      if (this.displayEvidenceOverlay) {
        window.addEventListener("keyup", this.onKeyUp);
      } else {
        window.removeEventListener("keyup", this.onKeyUp);
      }
    },
    onKeyUp(event) {
      if (event.key === Key.Escape) {
        this.displayEvidenceOverlay = false;
      }
    },
    onShow() {
      this.displayEvidenceOverlay = true;
    },
    onAccept() {
      this.displayEvidenceOverlay = false;
      this.$emit('accept-dispute');
    },
    onDismiss() {
      this.displayEvidenceOverlay = false;
    },
  },
});
